@import url(https://fonts.googleapis.com/css2?family=Chivo:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap);

:root {
    --background-color: #f8f7f6;
    --black-color: #2d2d2d;
}

@font-face {
    font-family: Amelie;
    src: url(./assets/fonts/amelie/Amelie-Regular.woff);
}

@font-face {
    font-family: VM;
    src: url(./assets/fonts/valerie/VMElegance-Regular.woff);
}

body {
    font-family: Chivo, sans-serif;
    font-weight: 300;
    background-color: var(--background-color);
    margin: 0;
}